.Template{

	padding-bottom:25px;
	//mix-blend-mode:multiply;

}

.Template-head{

	display:none;
	h1{
			padding-top:20px;
			@extend %u-text--hero;
			padding-bottom:20px;
		}

}

.Template--menu{

	.Template-head{
		display:block;
	}

	.Grid--links{
		max-width:600px;
		.Grid-item{
			width:50%;
			.Link{
				width:100%;
				padding-left:0;
				padding-right:0;
				min-width:0;
				white-space: nowrap;
			}
		}
	}

}


	.Template-heading{
		h1{
			padding-top:20px;
			@extend %u-text--hero;
			padding-bottom:20px;
		}
	}



.Template-mapCol,.Template-mapWrap{
	position:relative;
	

}

.Template-mapWrap{
	position:relative;



}

.Template-mapLink{
	position:absolute;
	bottom:25px;
	right:60px;
	z-index:2;
}

.Template-mapGrid{
	@include respond-to('tablet'){
		& > div{
			width:100%!important;
		}
	}
}

.Template-map{

	@include pad-ratio(6,6);
	height:100%;
	min-height:1200px;
	min-height:1400px;
	@include respond-to('tablet'){
		min-height:400px;
		@include pad-ratio(6,4);
	}
	@include respond-to('wide'){
		min-height:1024px;
		min-height:1200px;
	}
	@include respond-to('mobile'){
		min-height:400px;
	}
	position:relative;
	z-index:1;

	& > *{

		@extend %u-box--fluid;

	}

}

.Template-blocks{
	& > .Container{
		padding:0;
		max-width:none;
	}
}



.Template--post{

	.Template-head{

		display:block;
		margin-top:20px;
		position:relative;
		h1{
			@extend %u-text--hero;
		}

		.Template-sub{
			.Content{
				text-align:right;
				padding-top:0;

				*{
					padding-bottom:0;
					margin-bottom:0;
				}
			}

			@include respond-to('tablet-up'){
				position:absolute;
				top:0;
				right:0;
				display:block;
				padding-right:20px;
			}
			

		}

	}

}

.Template-intro{

	width:auto;
	height:calc(100vh - 180px);
	position:relative;

	margin:0px;

	@include respond-to('mobile'){
	height:calc(100vh - 250px);
	}

	.Template-media{
		position:absolute;
		top:10px;
		bottom:70px;
		left:10px;
		right:10px;

	}

	.Template-caption{

		position:absolute;
		bottom:0;
		left:0;
		right:0;
		text-align:center;
		padding-left:30px;
		padding-right:30px;
	

	}
}

