.Grid-item{
	
	@include responsive('padding-bottom',$base-padding);	

}

.Grid--contact{
	& > div{
		@include respond-to('tablet'){
			width:100%!important;
		}
	}

	& > div{
		@include respond-to('tablet-up'){
			padding-right:20px;
		}
	}
}

.Grid--links-2,
.Grid--links-3,
.Grid--links-4,
.Grid--links-5{

	.Grid-item{
		width:50%;
		.Link{
			display:block;
		}
	}
}
.Grid--article{

	.Grid-item{
		padding-bottom:0;
	}

}

.Grid--items{

	margin-left:0px;
	.Grid-item{

		width:50%;
		padding:30px;

		@include respond-to('mobile'){
			width:100%;
			padding:15px;
		}

		.Item{

			margin:0 auto;
			max-width:500px;

			.Image{
				border-radius:30px;
				@include respond-to('mobile'){
					border-radius:15px;
				}
			}

		}

		&.Grid-item--full{

			width:100%;
			.Item{
				max-width:1200px;
			}

		}

	}

}
.Grid--footer{

	@extend %u-grid;
	//max-width:1000px;
	width:100%;

	padding-bottom:50px;


	& > div{
		@extend %u-gridItem;
		
		width:100%;

		@include respond-to('tablet'){
			width:50%;
		}

		@include respond-to('desktop'){
			width:33.33333%;

			&:nth-child(3){
				width:33.33333%;
			
			}
		}

	
	}

	@include respond-to('mobile'){	
		padding-bottom:50px;

		.List{
			margin-bottom:10px;
		}
	}

}