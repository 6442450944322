html {
	overflow-x: hidden;
}

#bookonline {
	display: none !important;
}

@include respond-to("mobile") {
	html {
		overflow: hidden;
	}

	html.Html--open {
		overflow: visible;
		overflow-x: hidden;
	}
}

.Site,
#root {
	overflow: visible;
}

.Site-icon-address {
	padding-left: 30px;
	position: relative;
	display: inline-block;
	&:before {
		@extend %u-iconfont;
		@extend %u-iconfont--map;
		width: 20px;
		position: absolute;
		top: 25px;
		@include respond-to("mobile") {
			top: 18px;
		}

		left: 0;
		color: $red;
	}

	.Content {
		display: inline-block;
	}
}

.Site-icon-email {
	padding-left: 30px;
	position: relative;
	display: block;
	cursor: pointer;
	&:before {
		@extend %u-iconfont;
		@extend %u-iconfont--email;
		width: 20px;
		position: absolute;
		top: 25px;
		@include respond-to("mobile") {
			top: 15px;
		}
		cursor: pointer;
		left: 0;
		color: $red;
	}
}

.Site-icon-phone {
	padding-left: 30px;
	position: relative;
	display: block;
	cursor: pointer;
	&:before {
		@extend %u-iconfont;
		@extend %u-iconfont--phone;
		width: 20px;
		position: absolute;
		top: 25px;
		@include respond-to("mobile") {
			top: 15px;
		}
		cursor: pointer;
		left: 0;
		color: $red;
	}
}

.Html--urithanks {
	.Site {
		&:after {
			display: none;
		}
	}
}

.Site-intro {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	background: $green;
	//pointer-events:none;
	canvas {
		//pointer-events:none;
	}
	display: block;
	transition: opacity 1s 0.3s ease;

	.Site--urithanks & {
		opacity: 0;
		pointer-events: none;
		canvas {
			pointer-events: none;
		}
	}
	.Site--open & {
		display: none;
		opacity: 0;
		pointer-events: none;
		canvas {
			pointer-events: none;
		}
	}
	.Site--play & {
		display: block;
		opacity: 1;
		pointer-events: all;
		canvas {
			pointer-events: all;
		}
	}
}

.Site-splash-intro {
	position: absolute;
	top: 0%;
	left: 0;
	width: 100%;
	padding: 50px;

	@include respond-to("mobile") {
		padding: 30px 10px;
	}
	z-index: 2;
	pointer-events: none;
}

.Site-splash-enter {
	position: absolute;
	bottom: 5%;
	left: 0;
	width: 100%;
	padding: 50px;
	text-align: center;
	z-index: 2;
	opacity: 0;

	cursor: pointer;
	transition: opacity 0.5s ease;
	opacity: 0.5;
	pointer-events: none;
	.Link {
		color: $lightGreen;
		border-color: $lightGreen;
		letter-spacing: 0.2em;
		&:before {
			content: "Loading...";
		}
	}

	.Site--play &,
	.Site--preloaded & {
		opacity: 1;
		transform: translateY(0);
		.Link {
			pointer-events: all;
			color: $lightGreen;
			border-color: $lightGreen;
			background: rgba(255, 255, 255, 0.15);
			&:before {
				content: "Enter Site";
			}
		}

		.Link:hover {
			color: white;
			border-color: rgba(255, 255, 255, 0.8);
		}
	}

	.Site--open & {
		.Link {
			pointer-events: none;
		}
	}

	.Site--play & {
		.Link {
			pointer-events: all;
			&:before {
				//content:'Back To Site';
			}
		}
	}
}
.Site-page {
	min-height: 100%;
	margin: 0 25px;

	padding-top: 155px; // Allow for header
	overflow: hidden;
	overflow-x: hidden;

	display: none;
	opacity: 0;
	.Site--open & {
		opacity: 1;
		display: block;
	}
	.Site--play & {
		display: none;
	}

	@include respond-to("mobile") {
		margin: 0;
		padding-top: 75px;
	}
}

.Site-burger {
	display: none;
	@include respond-to("mobile") {
		display: block;
		position: fixed;
		top: 14px;
		right: 18px;
		height: 40px !important;
		//border:1px solid red;
		bottom: auto;
	}
}
.Site-head {
	position: fixed;
	top: 20px;
	left: 20px;
	right: 20px;
	z-index: 5;
	height: 125px;

	@include respond-to("mobile") {
		left: 0;
		right: 0;
		top: 0;
		z-index: 7;
		height: auto;
	}
}

.Site-head-top {
	//border-bottom:1px solid red;
	height: 75px;
	position: relative;
	z-index: 2;

	& > * {
		height: 100%;
	}

	@include respond-to("mobile") {
		height: 60px;
	}
}

.Site-head-bottom {
	//border-bottom:1px solid red;
	height: 53px;
	position: relative;
	margin-top: 2px;
	margin-left: 2px;
	margin-right: 3px;
	z-index: 1;
	transition: background 0.4s ease;

	.Site--scrolled & {
		background: url("/ui/img/texture-green-light.jpg");
		color: white;
		.List--icons a {
			transition: none;
		}
		a {
			color: lighten($green, 50%);

			&:hover {
				color: black;
			}
			&.is-active {
				color: $red;
			}
		}
	}

	& > * {
		height: 100%;
	}

	@include respond-to("mobile") {
		height: 50px;
		display: none;
	}

	ul.List {
		display: flex;
		align-items: stretch; /* Default */
		justify-content: space-between;
		width: 100%;

		margin: 0;
		padding: 0 15px;

		li {
			display: block;
			flex: 0 1 auto; /* Default */
			list-style-type: none;

			padding: 0;
			a,
			.Icon {
				display: block;
				height: 53px;
				line-height: 53px;

				@include respond-to("mobile") {
					height: 50px;
					line-height: 50px;
				}
			}
		}
	}

	ul.List--nav {
		display: block;
		padding-left: 22px;
		li {
			display: inline-block;
			padding-right: 20px;
		}
	}
}

.Site-splash {
	background: $green;
}

.Site-splash-logo {
	@extend %u-layout-centre;
	width: 90%;
	max-width: 1600px;
	z-index: 0;
	pointer-events: none;
}

.Site-stamps {
	position: absolute;
	top: 0;
	right: 0;
	padding-left: 3px;
	padding-right: 3px;
	li {
		width: 90px;
	}

	@include respond-to("mobile") {
		display: none;
		background: transparent !important;
		li {
			width: 60px;
		}
	}
}

.Site-shortcuts {
	height: 100%;
	width: 180px;
	//border:1px solid red;
	position: absolute;
	right: 0;
	bottom: 0;

	&.Site-shortcuts--has1,
	&.Site-shortcuts--has2 {
		width: 90px;
		ul.List {
			justify-content: center;
		}
	}

	@include respond-to("mobile") {
		display: none;
	}
}

.Site-mobileShortcuts {
	display: none;

	@include respond-to("mobile") {
		position: fixed;
		height: auto;
		bottom: 0px;
		left: 0px;
		right: 0px;

		top: auto;
		width: auto;
		z-index: 4;
		display: block;

		li {
			text-align: center;
		}
		a {
			display: block;
		}
		.List--has2 {
			li {
				width: (1/2) * 100%;
			}
		}
		.List--has3 {
			li {
				width: (1/3) * 100%;
			}
		}
		.List--has4 {
			li {
				width: (1/4) * 100%;
			}
		}
		.List--has5 {
			li {
				width: (1/5) * 100%;
			}
		}
	}
}

.Site-nav {
	height: 100%;
	width: 90%;
	//border:1px solid red;
	position: absolute;
	left: 0;
	bottom: 0;
	backface-visibility: hidden;
	@include respond-to("mobile") {
		width: 100%;
	}
}

.Site-burgerNav {
	display: none;
	background: rgba($green, 0.95);
	z-index: 6;
	padding-top: 80px;
	pointer-events: none;
	transition: opacity 0.4s ease;
	opacity: 0;
	overflow: hidden;
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;

	.Site--menu & {
		opacity: 1;
		pointer-events: all;
	}
	.List--burger {
		@extend %u-text--burger;
		text-align: center;
		li {
			padding: 15px;
		}
		* {
			color: white;
		}

		*.is-active {
			color: $red;
		}
		padding-bottom: 50px;
	}
	.List--stamps {
		li {
			&:before {
				display: none;
			}
		}
	}
	@include respond-to("mobile") {
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;

		.List {
		}
	}
}

.Site-banner {
	position: absolute;
	top: 0;
	right: 0px;
	left: 410px;
	text-transform: uppercase;
	overflow: hidden;

	@include respond-to("mobile") {
		display: none;
	}

	@include respond-to("tablet") {
		display: none;
	}

	a {
		text-decoration: underline;
	}

	.Banner {
		position: absolute;
		top: 52%;
		left: 0%;
		width: 100%;
		transform: translateY(-50%);
	}
}

.Site-logo {
	@include respond-to("tablet-up") {
		width: 400px;
	}

	@include respond-to("mobile") {
		width: 60%;
	}

	//border:1px solid red;
	position: relative;

	a {
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		//border:1px solid blue;
	}

	.Logo {
		display: block;
		width: 100%;
		height: 100%;
		padding: 0;

		@include hide-text();
	}
}

.Site-template {
	[data-rel="transition"] {
		&:before {
			content: "";
			padding-top: 1px;
			background: none;
			display: table;
		}
		& > * {
			//min-height:100vh;
			&:before {
				content: "";
				padding-top: 1px;
				background: none;
				display: table;
			}
		}
	}
}

.Site-book {
	display: none;
	@include respond-to("mobile") {
		display: block;
		position: fixed;
		top: 0;
		right: 50px;
		z-index: 7;
		height: 60px;
	}
}
@keyframes shake {
	5%,
	45% {
		transform: rotate(-1deg);
	}

	10%,
	40% {
		transform: rotate(2deg);
	}

	15%,
	25%,
	35% {
		transform: rotate(-4deg);
	}

	20%,
	30% {
		transform: rotate(4deg);
	}

	50% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
.Site-stamps #bookstamp {
	a {
		display: block;
		animation: shake 2.5s ease-in-out infinite;
		transform: rotate(0deg);
	}
}
.Site-menusContainer {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	@include respond-to("mobile") {
		display: none;
	}
}
.Site-menus {
	transition: opacity 1s ease, transform 1s ease;
	//transform:translateY(100px);
	height: 0;
	overflow: visible;
	opacity: 0;
	pointer-events: none;
	position: relative;

	.Site--noMenus & {
		transition: opacity 1s ease, transform 1s ease, padding 1s 1s ease;
	}
	.Site--menus & {
		transition: opacity 1s ease, transform 1s ease, padding 0 0s ease;
		height: auto;
		height: 0;
		padding-top: 130%;

		overflow: visible;
		opacity: 1;
		pointer-events: all;
		//transform:translateY(0);
	}
}

.Site-instagram {
	padding: 50px 0;
	padding-top: 30px;
}

.Site-foot {
	background: rgba(244, 242, 238, 0.7);
	.Content *,
	.Form * {
		@include font-size(14px);
	}

	@include respond-to("tablet") {
		.Form {
			width: 100%;
		}
	}

	@include respond-to("mobile") {
		.Content,
		.Form,
		.List--icons {
			padding-left: 30px;
		}

		.Site-icon-email,
		.Site-icon-address,
		.Site-icon-phone {
			.Content {
				padding-left: 0;
			}
		}
	}
}

.Site-message {
	.Site-message-content {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		background: white;
		z-index: 99;
		padding: 20px;
		border: 1px solid $red;
		background: rgb(244, 242, 238);

		width: 80%;
		max-width: 500px;
	}

	transition: opacity 0.4s ease;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	.Content {
		text-align: center;
		margin: 0 auto;
	}

	.Site--noMessage & {
		opacity: 0;
		pointer-events: none;
	}

	.Site-message-bg {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: red;
		z-index: 88;
		background: rgba(244, 242, 238, 0.7);
	}
}
.Site-close {
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	* {
		color: $red;
	}
}

.Site-scrollDown {
	padding-bottom: 20px;
	cursor: pointer;
	&:after {
		content: "";
		background: url("/ui/img/icons/arrow-down.svg");
		background-repeat: no-repeat;
		background-position: center center;
		z-index: 5;
		width: 50px;
		height: 30px;
		margin: 0 auto;
		display: block;
	}
	position: fixed;
	bottom: 20px;
	left: 20px;
	right: 20px;
	pointer-events: none;

	transition: opacity 0.5s ease, bottom 0.5s ease;
	opacity: 0;

	@include respond-to("mobile") {
		bottom: 50px;
		left: 0;
		right: 0;
	}

	.Site--home & {
		opacity: 1;
		pointer-events: all;
	}
	.Site--home.Site--scrolled & {
		bottom: 0px;
		opacity: 0;
		pointer-events: none;
	}
}

.Site-stamp--menu {
	position: absolute;
	top: -75px;
	right: 10%;
	display: block;
	z-index: 1;
	width: 150px;
	height: 150px;
	background: url("/ui/img/click-on-a-menu.svg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	animation: spin 10s linear infinite;
}

.Site-cookies {
	//border:1px dotted rgba(0,0,0,0.3);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(117, 124, 111, 0.85);
	color: white;
	padding: 10px;
	z-index: 10;
	transition: opacity 0.4s ease;
	pointer-events: none;
	opacity: 0;
}

.Site--withCookieMessage {
	.Site-cookies {
		pointer-events: all;
		opacity: 1;
	}
}
