.Link--callToAction{

	border:1px dotted $red;
	color:$red;
	text-transform:uppercase;
	padding:10px 20px;
	display:inline-block;
	text-align:center;

	&.Link--download{
		min-width:270px;
	}
	&:hover{
		border-color:black;
		color:black;
	}

	span{

		display:inline-block;
		vertical-align:middle;

	}
}

.Link--mapLink{

	background:#EEEDE8;

}
.Link--back{

	.Link-body{

		display:none;

	}

	width:20px;
	height:20px;
	display:block;
	line-height:20px;
	//border:1px solid red;

	&:after{
		width:200%;
		height:200%;
		transform:translate(-50%,-50%) scale(0.2);
		border-radius:50%;
		background:white;
		border:none;
	}

	.Link-arrow{

		width:20px;
		height:20px;


		
		
		@include respond-to('mobile'){
			
			width:16px;
			height:16px;
		
			
		}

		display:block;
		position:absolute;
		top:0;
		left:0;
		margin:0;

		svg{

			width:20px;
			height:20px;

			@include respond-to('mobile'){
			
				width:16px;
				height:16px;
		
			
			}

		}

		svg polygon{

			fill:$red!important;

		}

		&.Link-arrow--back{

			transform:translateX(4px);

			.Html--touch &{
				transform:translateX(0px);
			}

		}

	}

	&:hover{

		.Link-arrow--back{

			transform:translateX(0);

		}
		&:after{

			transform:translate(-50%,-50%) scale(1);

		}

	}

}