.Form{
	width:80%;

	max-width:400px;
	

	position:relative;
	padding-bottom:12px;

	*{
		@extend %u-text--body;
	}
}
.Form-heading{

	color:$red;
	text-align:center;
	margin-bottom:12px;
}

.Form button{

	color:$green;
	position:absolute;
	bottom:8px;
	right:0;

	width:14px;
	height:33px;

	@include respond-to('mobile'){

		height:20px;
		
	}

	polygon{

		fill:rgb(122,122,122);

	}



}

.Form input,
.Form textarea{

	color:black;
	background:transparent;
	border-bottom:1px dotted rgba(0,0,0,0.35);
	display:block;
	width:100%;
	position:relative;
	transition:border 0.4s ease;
	line-height:150%;
	text-align:left;

	&:focus{

		border-bottom:1px dotted rgba(0,0,0,1);

	}

}

.Form textarea{
	height:150px;
}

.Form--basic{

	input,textarea{
		margin-bottom:10px;
	}
	button{

		position:relative;
		top:auto;
		right:auto;
		bottom:auto;
		width:auto;
	}
	.Form-submit{
		text-align:left;
		button{
		border:1px dotted $red;
	color:$red;
	text-transform:uppercase;
	padding:10px 20px;
	display:inline-block;
	text-align:center;
	height:auto;

	&:hover{
		border-color:black;
		color:black;
	}
}
	}
}