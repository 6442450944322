%u-dots{

	background:url('/ui/img/dots.png');
	background-size:33px 35px;
	@media 
	(-webkit-min-device-pixel-ratio: 1.25), 
	(min-resolution: 120dpi){ 
    /* Retina-specific stuff here */
    background-size:33px 35px;
	}

}

%u-paper{
	
	background:url('/ui/img/texture-chalk.jpg');
	background-color:rgb(230,230,220);
	background-attachment:fixed;

	@media 
	(-webkit-min-device-pixel-ratio: 1.25), 
	(min-resolution: 120dpi){ 
    /* Retina-specific stuff here */
    background-size:128px 128px;
	}
	

}
html{

	background:$green;

}
html.Html--open{

	/*
		
		Main background

	*/

	@extend %u-paper;

	/*
		
		Bottom paper mask

	*/
	&:after{
		content:'';
		display:block;
		
		position:fixed;
		pointer-events:none;


		@extend %u-paper;
		bottom:0;
		left:0;
		right:0;
		height:25px;
		z-index:6;

		@include respond-to('mobile'){
			height:18px;
			display:none;
		}

	}

	/*
		
		Top paper mask (under header)

	*/
	&:before{
		content:'';
		display:block;
		
		position:fixed;
		pointer-events:none;

		
		@extend %u-paper;
		top:0;
		left:0;
		right:0;
		height:0;
		padding-top:155px; // Allow for header plus 5px

		z-index:4;

		@include respond-to('mobile'){
			padding-top:65px;
		}

		

	}

	/*
		
		Behind stamps to cover ticker

	*/

	.Site-stamps{

		@extend %u-paper;

	}

	.Site-shortcuts{

		@extend %u-paper;
		@include respond-to('tablet-up'){
			background:transparent;
		}

	}

}

.Site-page{
	@extend %u-paper;
}
.Site-scrollDown{
	@extend %u-paper;
	&:before{
		content:'';
		display:block;
		width:100%;
		height:3px;
		@extend %u-dots;
		margin-top:3px;
		margin-bottom:20px;
	}
}

.Site{

	/*
		
		Frame Top dotted line

	*/
	&:after{
		@extend %u-dots;
		content:'';
		display:block;
		height:3px;
		
		position:fixed;
		top:20px;
		left:20px;
		right:20px;
		z-index:6;

		@include respond-to('mobile'){
		top:15px;
		left:15px;
		right:15px;
		display:none;
		}
	}

	/*
		
		Frame Bottom dotted line

	*/

	&:before{
		content:'';
		display:block;
		height:3px;
		@extend %u-dots;
		position:fixed;
		bottom:20px;
		left:20px;
		right:20px;
		z-index:7;

		@include respond-to('mobile'){
			bottom:15px;
			display:none;
		}


	}

}
.Html--open body{

	/*
		
		Frame Left dotted line

	*/

	&:after{
		content:'';
		display:block;
		width:3px;
		@extend %u-dots;
		position:fixed;
		top:20px;
		left:20px;
		bottom:20px;
		z-index:6;

		@include respond-to('mobile'){

			height:150vh;
			bottom:auto;
			transform:translate3D(0,0,0);
			left:15px;
			top:15px;
			display:none;

		}
	}

	/*
		
		Frame Right dotted line

	*/

	&:before{
		content:'';
		display:block;
		width:3px;
		@extend %u-dots;
		position:fixed;
		top:20px;
		right:20px;
		bottom:20px;
		//border:1px solid red;
		z-index:6;

		@include respond-to('mobile'){

			height:150vh;
			bottom:auto;
			transform:translate3D(0,0,0);
			right:15px;
			top:15px;
			display:none;

		}

	}

}


.Site-head,.Site-head-top{

	/*
		
		Header horizontal dotted lines

	*/

	&:after{
		content:'';
		display:block;
		height:3px;
		@extend %u-dots;
	}

	&.Site-head{

		&:after{

			position:fixed;
			top:150px;
			left:20px;
			right:20px;

			@include respond-to('mobile'){
				left:15px;
				right:15px;
				top:125px;
				display:none;
			}
		}

	}

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		height:100%;
		height:75px;
		width:3px;
		left:410px;
		margin-left:-5px;
		@extend %u-dots;

		@include respond-to('mobile'){
			display:block;
			right:60px;
			height:100%;
			left:auto;
		}

		
	}

}

.Site-head,
.Site-head-top{

	&:before{

		@include respond-to('tablet'){
			display:none!important;
		}

	}
}

/*
		
	<hr /> horizontal dotted lines

*/

hr{
	
	
	display:block;
	border:none;
	border-style:none;
	&:after{
		content:'';
		display:block;
		height:3px;
		@extend %u-dots;
	}

	
	height:3px;
	margin:0;

}

.Site-shortcuts{
	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:0;

		height:100%;
		width:3px;
	
	
		@extend %u-dots;

		@include respond-to('mobile'){
			display:none;
		}
	}

	&:after{

		content:'';
		display:block;
		position:absolute;
		top:2px;
		left:0;
		right:0;

		height:3px;
		width:100%;
	
	
		@extend %u-dots;

		@include respond-to('tablet-up'){
			display:none;
		}
	}
}

.Site-mobileShortcuts{
	@extend %u-paper;
	padding-top:3px;
	&:before{
		content:'';
		display:block;
		position:absolute;
		top:3px;
		left:0;

		width:100%;
		height:3px;
	
	
		@extend %u-dots;

		
	}

	li{
		position:relative;
		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;

			height:100%;
			width:3px;
		
		
			@extend %u-dots;

		}

		&:first-child{
			&:before{
				display:none;
			}
		}
	}


}

.Template-map{

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:-3px;

		height:100%;
		width:3px;
	
	
		@extend %u-dots;

		@include respond-to('mobile'){
			display:none;
		}
	}



}

.List--stamps{

	li{
		position:relative;
		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;

			height:100%;
			width:3px;
		
		
			@extend %u-dots;
		}

	}

}

.Site-burgerNav .List--stamps{
	
}

.Block--divider{

	padding-top:50px;
	padding-bottom:50px;

	@include respond-to('mobile'){
		padding-top:15px;
		padding-bottom:15px;
	}
	
	&:after{
		content:'';
		display:block;
		height:3px;
		@extend %u-dots;
	}

}

