.Loader{
	@include hide-text;
	position:fixed;
	@include responsive('top',$base-padding);
	@include responsive('left',$base-padding);
	background:$base-ui-color;
	border-radius:50%;
	width:20px;
	height:20px;
	animation: pulse 1s infinite;
	

}