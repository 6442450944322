$max:1200px;

.Menus{

	.Image{
		border-radius:0;
	}

}
@include respond-to('tablet-up'){
	.Menus{

		height:100%;
		width:100%;
		position:absolute;
		top:0;
		left:0;
		z-index:1;

		.Image-caption{
			display:none;
		}

		& > *{

			position:absolute;
			top:0%;
			left:0%;
			//transform:translate(-50%,-50%);
			display:block;
			transition:all 0.5s ease;
			cursor:zoom-in;
			&.is-active{

				cursor:zoom-out;

			}
		}

		.Menus-food{
			width:80%;
			//max-width:$max * 0.8;
			z-index:3;
			transform:translate(5%,10%)  rotate(-1deg);

			&.is-active{
				
				z-index:4;
				top:10%;
				left:50%;
				transform:translate(-50%,0) rotate(0deg) scale(1.1);

			}
		}

		.Menus-drinks{
			width:60%;
			//max-width:$max * 0.6;
			z-index:2;
			transform:translate(55%,30%)  rotate(2deg);

			&.is-active{
				
				z-index:4;
				top:10%;
				left:50%;
				transform:translate(-50%,0) rotate(0deg) scale(1.1);

			}
		}

		.Menus-platters{
			width:75%;
			//max-width:$max * 0.75;
			z-index:1;
			transform:translate(20%,0%) rotate(-2deg);

			&.is-active{
				
				z-index:4;
				top:10%;
				left:50%;
				transform:translate(-50%,-10%) rotate(0deg) scale(1.1);

			}

		}

		.Menus-item{
			background:$green;
			box-shadow:0px 0px 5px rgba(0,0,0,0.3);

		}


	}
}