.Item a{

	display:block;

}

.Item--instagram{
	overflow:hidden;
	display:block;
	border-radius:30px;
	background:$green;
	position:relative;
	overflow:hidden;
	width:100%;

	.Item-media,.Image{
		margin:0;
		border-radius:30px;
		overflow:hidden;
	}
	.Item-body{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background:rgba($green,0.8);
		color:white;
		transition:opacity 0.4s ease;
		opacity:0;
		span{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			 @include font-size(13px);
			 display:block;
			 padding:10px;
			 width:100%;
		}
	}

	&:hover{
		.Item-body{
			opacity:1;
		}
	}
}

.Item--feature{

	.Item-head{

		@extend %u-text--hero;
		padding-bottom:40px;

		@include respond-to('mobile'){
			padding-bottom:15px;
		}

	}

	.Item-body{
		max-width:600px;
		padding-bottom:40px;

		@include respond-to('mobile'){
			padding-bottom:15px;
		}
	}

	.Item-foot{

		a{
			display:inline-block;
		}

	}

	.Item-media{
			padding-bottom:20px;
		}

	@include respond-to('mobile'){
		.Item-media{
			padding-bottom:15px;
		}
	}

	@include respond-to('desktop'){

		.Block--fullLeft &,
		.Block--fullRight &{

			
			.Item-media{
				width:50%;
				display:inline-block;
				vertical-align:top;
			}

			.Item-content{
				width:50%;
				display:inline-block;
				vertical-align:top;
				padding-left:40px;
			}

			.Item-media{

				padding-right:40px;

			}

		}

		.Block--fullRight &{

			direction:rtl;

			.Item-content{
				direction:ltr;
				width:50%;
				display:inline-block;
				vertical-align:middle;
				padding-left:0px;
				padding-right:40px;
			}

			.Item-media{

				padding-left:40px;
				padding-right:0;

			}

		}

	}

	

}

.Item.Item--grid{

	h1{
		margin-bottom:0;

	}
	time{
		padding-bottom:20px;
		display:block;
	}

	.Item-foot{
		a{
			display:inline-block;
		}
	}

}

.Item.Item--carousel{

	text-align:center;
	position:relative;
	//border:1px solid red;
	overflow:hidden;
	border-radius:30px;

	.Item-media,.Image,.Image-media{
		overflow:hidden;
		border-radius:30px;
	}
	.Item-body{
		//padding-bottom:20px;
		text-align:center;
		text-transform:uppercase;
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		
		
		height:auto;
		z-index:1;
		
		
	}

	a{

		@include font-size(12px);
		text-transform:none;
		text-align:right;
		color:white;
		//text-shadow:1px 1px rgba(white,0.8);
		//border-bottom:1px dotted transparent;
		display:block;
		width:100%;
		z-index:2;
		position:relative;
		background:rgba($green,0.75);
		
		padding:8px 40px;
		

		&:before{
			//content:'link';
		}

		&:hover{
			//border-color:$red;
			&:after{
				transform:translate(15px,-12px);
			}
		}

		&:after{
			@extend %u-iconfont;
			@extend %u-iconfont--arrow-right;
			font-size:10px;
			margin-left:4px;
			//position:absolute;
			//top:50%;
			//left:100%;
			transition:transform 0.4s ease;

			transform:translate(7px,-12px);


		}
	}

	.Item-media{

		position:relative;
		@include pad-ratio(6,5);

		.CarouselGallery--half &{
			@include pad-ratio(6,4);
		}
		//margin-bottom:10px;

		.Image{
			border-radius:0;
		}


	}
}
/*

	Default Item forces 6:4 image ratio.
	CMS outputs a modifier based on the 'style' setting per block, so this is just the styling for --default

*/
/*.Item--default{

	.Item-media{

		@extend %u-box--ratio;
		@include pad-ratio(6,4);

		& > *{

			padding-top:0!important;
			position:absolute!important;
			height:100%!important;
			width:100%!important;

		}

	}

	.Item-media{

		margin-bottom:var(--base-margin);

	}

	margin-bottom:var(--base-margin);

}*/


