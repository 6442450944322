$interface: $red;
.CarouselGallery {
  .CarouselGallery-item {
    padding: 0 5vw;
    width: 60vw;

    @include respond-to("mobile") {
      width: 60vw;
      padding: 3vw;
    }
  }

  &.CarouselGallery--half {
    width: 100%;
    white-space: nowrap;
    height: 42vw;
    height: 0;
    @include pad-ratio(6, 4);
    padding-bottom: 30px;
    position: relative;

    .slick-slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    & > * {
      height: 100%;
    }
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .CarouselGallery-item {
      width: calc(50vw - 50px);
      padding: 0px;
    }
  }

  &.CarouselGallery--hero {
    width: 100%;
    white-space: nowrap;
    height: 80vh;
    position: relative;

    & > * {
      height: 100%;
    }
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slick-dots {
      display: none !important;
    }
    .CarouselGallery-item {
      padding: 0;
      width: auto;
      overflow: hidden;

      width: calc(100vw - 50px);
      height: 80vh;

      position: relative;

      .Item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 50px;

        .Item-media {
          top: 0;
          left: 30px;
          right: 30px;
          bottom: 50px;
          height: auto;
          padding: 0;
          position: absolute;
        }

        .Item-body {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  &.CarouselGallery--half .slick-slider .slick-arrow {
    bottom: 20px;
  }

  .slick-slider {
    .slick-arrow {
      position: absolute;
      top: 0;
      left: 0px;
      width: 50px;
      bottom: 40px;

      z-index: 2;
      // opacity: 0;
      background: transparent;
      &:focus {
        outline: none;
      }
      cursor: pointer;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 30% auto;
      @include hide-text();
    }

    .slick-arrow.slick-next {
      right: 0px;
      left: auto;

      cursor: pointer;
      background-image: url("/ui/img/icons/arrow-right.svg");
    }
    .slick-arrow.slick-prev {
      background-image: url("/ui/img/icons/arrow-left.svg");
    }

    .slick-dots {
      margin: 0 auto;
      position: relative;
      width: 50%;
      text-align: center;

      li {
        display: inline-block;
        cursor: pointer;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: transparent;
        border: 2px solid $interface;
        transition: background 0.5s ease, border 0.5s ease;
        margin: 3px;
        position: relative;
        button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        &.slick-active {
          background: $interface;
          border: 2px solid $interface;
        }
        @include respond-to("mobile") {
          width: 12px;
          height: 12px;
          margin: 2px;
          // border: 1px solid $yellow;
        }
        button {
          // display: none;
        }
        // &.is-active{

        //     background:white;
        //     border:2px solid white;

        // }
      }
    }
  }
}
