.Image {
	overflow: hidden;
	border-radius: 30px;
	background: $green;
	transform: translate3D(0px, 0px, 0);

	@include respond-to("mobile") {
		border-radius: 20px;
	}

	//@extend %u-paper;

	& > * {
		//mix-blend-mode:multiply;
	}

	.Site-instagram & {
		border-radius: 20px;
		@include respond-to("mobile") {
			border-radius: 15px;
		}
	}

	.Image-media {
		transition: opacity 1s ease;
		opacity: 0;
	}

	.Image-media[style],
	.Image-media[src] {
		//animation:fadeIn 1s 0.1s forwards;
		backface-visibility: hidden;
		opacity: 1;
	}

	transition: background 0.4s 1s ease;

	&.Image--visible.Image--loaded {
		.Image-media {
			opacity: 1;
		}

		background: transparent;
	}
}

[data-rel="transition"] {
	& > * {
		transform: translate3D(0px, 0px, 0);
	}
}
