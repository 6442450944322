.Blocks--debug{
	.Block--debug{

		margin-top:5px;
		border-top:1px solid $base-ui-color;
		border-right:none;
		border-left:none;

		&:before{

			content:attr(data-type) " \02193";
			display:inline-block;
			background:$base-ui-color;
			color:$base-background;
			padding:5px;
			
			margin-bottom:5px;


		}

		.Image{

			&:before{

				content:attr(data-width) "px";
				display:inline-block;
				background:$base-background;
				padding:5px;
				font-family:monospace;
				font-size:10px;
				margin-bottom:20px;
				position:absolute;
				top:10px;
				right:10px;
				z-index:1;

			}

		}
	}

	

}

.Block--anchor{
	height:0vh;
	padding:0;
	display:block!important;
}

.Block--illustration{
	&:after{
		content:'';
		display:block;
		padding-top:86%;
		padding-bottom:30px;
	}

		
		background-image:url('/ui/img/Berenjak_Map_Red.png');
		background-size:100% auto;
		background-position:top center;
		background-repeat:no-repeat;
	

	&.Block--green{
	
			background-image:url('/ui/img/Berenjak_Map_Green.png');
		
	}
}

.Block--rice{


	position:relative;
	height:300px;
	width:100%;
	margin-left:auto;
	margin-right:auto;

	@include respond-to('mobile'){
		height:80px;
		width:100%;
		margin-left:auto;
		margin-right:auto;
	}

	& > [data-layout="fluid"]{
		z-index:1;
		&:hover{
			//background:pink;
		}

		div{

			top:50%;
			bottom:auto;
			transform:translateY(-50%);
			height:300%;
			pointer-events:none;
			
			z-index:-1;

			canvas{
				z-index:-1;
				
				pointer-events:none;
			}
		}
	}

}

.Block-heading{

	@extend %u-text--hero;
	text-align:center;
	padding-bottom:50px;


}

.Block-content{

	margin:30px 20px;

	@include respond-to('mobile'){
		margin:15px;
	}

}

.Block--embed{

	.Block-content{

		margin-left:auto;
		margin-right:auto;
		iframe{
			display:block;
			margin:0 auto;
			width:100%!important;
			height:75vh!important;
		}

	}

	&.Block--half{

		width:100%;
		display:block;

		.Block-content{
			max-width:500px;

			iframe{

				height:50vh!important;
				max-height:350px;
			}

		}

	}
}

.Block--carousel{
	&.Block--half{
		@include respond-to('desktop'){
			padding-left:40px;
		}
	}
	&.Block--full .Block-content{
		margin-left:0;
		margin-right:0;
	}

	.Grid-item{
		padding-bottom:0;
	}
}

.Block--newsletter,.Block--form{

	.Block-content{

		margin-left:auto;
		margin-right:auto;
		max-width:500px;

		form{
			margin-left:auto;
			margin-right:auto;
		}

	}

	&.Block--full{

		.Content{
			text-align:center;
		}

	}

}

.Block--text{
	
	.Block-content{
		position:relative;
		//max-width:1200px;
	}
	@include respond-to('tablet-up'){
	.Grid--links{
		padding-top:20px;
		position:absolute;
		top:0;
		right:0;
		.Grid-item{
			text-align:right;
		}
	}

	}

	.Block-heading{
		padding-bottom:20px;
	}

	&.Block--default,
	&.Block--left{
		.Block-heading{
			text-align:left;
		}
	}

	&.Block--half{
		
		@include respond-to('tablet-up'){
			padding-right:40px;
			.Grid--links{
				padding-top:20px;
				position:relative;
				top:auto;
				right:auto;
				.Grid-item{
					text-align:left;
				}
			}

		}
	}

}

.Block--callToAction{
	padding:40px;
	.Block-content{
		text-align:center;
		margin-left:auto;
		margin-right:auto;
		max-width:500px;
	}

}

.Block--map{

	

	.Block-content{
		margin-left:0;
		margin-right:0;

		@extend %u-box--ratio;
		@include pad-ratio(6,4);

		& > *{

			@extend %u-box--fluid;

			& > *{

				@extend %u-box--fluid;

				& > *{

					@extend %u-box--fluid;

				}
			
			}



		}

	}

}

.Block--half{
	@include respond-to('desktop'){
		display:inline-block;
		width:50%;
		vertical-align:top;

		&.Block--image,
		&.Block--carousel{
			padding-top:5px;
		}
	}
}
