%u-text--intro{
	font-family:$base-font-family;
	letter-spacing:0.35em;

	@include font-size(20px);

	@include respond-to('mobile'){
		letter-spacing:0.3em;
		@include font-size(16px);

	}

	@include respond-to('tablet'){

		@include font-size(18px);

	}
}

%u-text--hero{
	font-family:$base-font-family-hero;
	text-transform:uppercase;

	@include font-size(50px);

	@include respond-to('mobile'){

		@include font-size(30px);

	}

	@include respond-to('tablet'){

		@include font-size(40px);

	}

	color:$red;
	line-height:110%;

	*{
		color:$red;
	}
}

%u-text--burger{
	font-family:$base-font-family-hero;
	text-transform:uppercase;

	@include font-size(48px);

	@include respond-to('mobile'){

		@include font-size(24px);

	}

	@include respond-to('tablet'){

		@include font-size(38px);

	}

	color:$red;
	line-height:110%;

	*{
		color:$red;
	}
}

%u-text--body{
	font-family:$base-font-family;
	letter-spacing:0.05em;
	@include font-size($base-font-size);

	@include respond-to('mobile'){

		@include font-size(14px);

	}

	@include respond-to('tablet'){

		@include font-size(16px);

	}

}

%u-text--nav{

	@include respond-to('tablet'){
		@include font-size(14px);
	}

	@include respond-to('mobile'){
		@include font-size(14px);
	}

}

%u-text--long{
	font-family:$base-font-family;
	letter-spacing:0.05em;
	@include font-size(16px);

	@include respond-to('mobile'){

		@include font-size(14px);

	}


}

%u-text--notice{
	font-family:$base-font-family;
	@include font-size(14px);

	@include respond-to('mobile'){

		@include font-size(11px);

	}

}

%u-text--small{
	font-family:$base-font-family;
	@include font-size(12px);

	@include respond-to('mobile'){

		@include font-size(8px);

	}

}