@keyframes scrollLeft {
    from {left: translateX(0%);}
    to {transform: translateX(-50%);}
}

@keyframes scrollRight {
    from {transform: translateX(-50%);}
    to {transform: translateX(0%);}
}


.Banner {

  width: 100%;
  position: relative;
  //left: 50%;
  //right: 50%;
  //margin-left: -50vw;
  //margin-right: -50vw;
  //margin-bottom: 5px;
  //width: 200vw;
  //height: 76px;

  white-space: nowrap;

  ul {
    white-space: nowrap;
    width: auto;
    display:inline-block;
    overflow: hidden;
    
   animation: scrollLeft 60s infinite;
    animation-timing-function: linear;


  }

  &:hover{

      ul{

           animation-play-state: paused;

      }

  }
  ul li {
    display: inline-block;
    margin-right: 0px;

    a{
      text-decoration:none;
    }
   
    &:before{

      
        margin:0 20px;

    

          content:'';
          display:inline-block;
          width:15px;
          height:15px;
         transform:translateY(3px);
         

          background:url('/ui/img/diamond.png');
          background-size:100% auto;
          background-position:center center;
          background-repeat:no-repeat;

      

    }


  }

  
}


