.List--nav{

  @extend %u-text--nav;

  li{

  	display:inline-block;
    text-transform:uppercase;
    a{
      color:black;
      position:relative;
      &:after{

          content:'';
          display:block;
          width:15px;
          height:15px;
          position:absolute;
          top:100%;
          left:50%;
         
          transform:translate(-50%,-10%);
          opacity:0;
          background:url('/ui/img/diamond.png');
          background-size:100% auto;
          margin-top:1px;
          transition:opacity 0.4s ease, transform 0.4s ease;
      }

      &:hover{
        color:$green;
      }

    }
    @include responsive('padding-right',$base-padding);

  }
	.is-active{

		color:$red;
    &:hover{
      color:$red;
    }
    &:after{

        transform:translate(-50%,-50%);
        opacity:1;

    }

	}



}

.List--stamps{

  @extend %u-text--small;
  height:100%;

  li{

    display:inline-block;
    text-transform:uppercase;

    height:100%;
    
    @include respond-to('mobile'){
        margin:0 10px;
        .Icon{
          width:50px;
          height:50px;
          font-size:50px;
          line-height:50px;

          @include when-inside('.Site-book'){
            width:55px;
            height:30px;
            font-size:30px;
            line-height:30px;
            margin-top:3px;

            + span{
              font-size:12px;
              margin-top:-4px;
            }

          }
        }
        @include font-size(14px);

        
      }

    a{
      color:$red;
      display:block;
      line-height:100%;

      @include respond-to('mobile'){
        padding-top:3px;
      }
      
      &:hover{
        .Icon{
          transform:scale(1.2);
        }
      }
    }

 
    
  }
  text-align:center;
  .Icon{

      display:inline-block;
      font-size:40px;
      width:40px;
      height:40px;
      margin:5px;
      overflow:hidden;
      transition:transform 0.2s $easeOutBack;

      @include respond-to('mobile'){
        //width:30px;
        //height:30px;
        //font-size:30px;
      }

  }

  span{

      display:block;
      white-space:nowrap;

  }
  .is-active{

    text-decoration:underline;

  }



}

.List--icons{


  li{

    display:inline-block;
    text-transform:uppercase;
    a{
      color:$red;
      &:hover{
        opacity:0.7;
        //color:black;
      }
    }
    padding:10px;
    
  }
  .is-active{

    text-decoration:underline;

  }

  .Site-foot &{
      li{
        padding-top:0;
        padding-left:0;
        margin-top:-5px;
      }
  }

}

.List--instagram{

  width:100%;
  position:relative;



  @include respond-to('mobile'){
    width:90%;
    margin-left:auto;
    margin-right:auto;
  }
 
  li{

    display:inline-block;
    padding:20px;
    width:(1/5) * 100%;

  }

  padding-bottom:30px;

  @include respond-to('mobile'){
    li{
      width:50%;
      padding:10px;
      &:last-child{
        display:none;
      }
    }
  }


}

.List--social{
  
  text-align:center;
  padding-top:24px;
  padding-bottom:12px;
  

  li{

      display:inline-block;
      color:$red;
      margin:0 10px;

  }

  a{

    color:$red;
    &:hover{

      opacity:0.5;

    }

  }
}

.List--footer{

  li{

      display:inline-block;
      &:after{

          content:'/';
          display:inline-block;
          margin:0 5px;


      }
      &:last-child{

          &:after{
            display:none;
          }

      }
  }

  a{

    text-decoration:underline;

    &:hover{

      text-decoration:none;

    }

  }
}
