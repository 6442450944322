.Burger{
	width:30px;
	height:30px;
	position:relative;
}
.Burger-line{

			display:block;
			width:100%;
			height:4px;
			background:$red;
			position:absolute;
			//opacity:0.5;
			top:50%;
			left:0;
			transform:translateY(-50%);
		

			&.Burger-line--top{
				transition:background 0.5s ease, transform 0.5s 0.5s ease,opacity 0s 0.5s;
				transform:translateY(-10px);

			}
			&.Burger-line--bottom{
				transition:background 0.5s ease, transform 0.5s 0.5s ease,opacity 0s 0.5s;
				transform:translateY(8px);

			}

			&.Burger-line--middle{
				height:2px;
				width:60%;
				transition:opacity 0s 0.5s;
				transform:translate(26%,0);

			}
			
			&.Burger-line--x1,
			&.Burger-line--x2{
				opacity:0;
				transition:background 1s $easeOutExpo, transform 1s $easeOutExpo,opacity 0s 0.5s;

			}
		}

	
.Site--menu,.Burger--cross{

			.Burger-line{

				

				&.Burger-line--top{
					transition:background 0.5s ease, transform 0.5s ease,opacity 0s 0.5s;
					transform:translateY(-50%);
					opacity:0;

				}
				&.Burger-line--bottom{
					transition:background 0.5s ease, transform 0.5s ease,opacity 0s 0.5s;
					transform:translateY(-50%);
					opacity:0;


				}

				&.Burger-line--middle{

					opacity:0;

				}

				&.Burger-line--x1{
					opacity:1;
					transition:background 1s $easeOutExpo, transform 1s 0.5s $easeOutExpo;
					transform:translateY(-50%) rotate(-45deg);

				}

				&.Burger-line--x2{
					opacity:1;
					transition:background 1s $easeOutExpo, transform 1s 0.5s $easeOutExpo;
					transform:translateY(-50%) rotate(45deg);

				}

			}

	}