.Content{
	text-align:left;
	margin-right:auto;
	max-width:600px;
	padding-top:25px;

	@include respond-to('mobile'){
		padding-top:15px;	
	}

	*{
		@extend %u-text--body;
		

	}

	
	p{

		padding-bottom:24px;



	}
	h1,h2,h3,h4,h5{
		
		color:$red;
		text-transform:uppercase;

		a{

			color:$red;

		}
		&:after{

			content:'*';
			display:none;
			padding:24px 0;
			text-align:center;

			@include respond-to('mobile'){

				padding:18px 0;

			}

		}

		margin-bottom:24px;

	}

	h3{
		margin-bottom:0;
	}

	p + h2{
		margin-top:20px;
	}

	a{

		border-bottom:1px dotted $green;
		display:inline-block;
		transition:border 0.4s ease;

		&:hover{

			border-color:transparent;

		}

	}

	table{
		p{
			
		}

		tr{

			td{
				padding:6px;
				border-top:1px solid $green;
				vertical-align:top;
				text-align:left;


			}

		}

		thead{
			td{

				border-top:3px solid $green;

			}
			p{

				padding:0;

			}
		}

		border-bottom:1px dotted $green;
		margin-bottom:24px;
		padding-bottom:24px;

	}

	nav{

		h1{

			margin-bottom:0;

		}

		a{

			border-color:transparent;
			&:hover{

				border-color:black;

			}

		}

	}

	img{

		border-radius:20px;
		margin:15px auto;
	}

}

.Content.Content--intro{

	text-align:center;
	margin:0 auto;

	*{
		@extend %u-text--intro;
		color:$lightGreen;
		//letter-spacing:0.2em;
	}
}

.Content--basic,
.Block--text.Block--centre .Content--article{

	width:80%;
	max-width:850px;
	margin-left:auto;
	text-align:justify;

	figure a{

		border:none;


	}
	figure{

		margin-bottom:20px;

	}

	h2,h3{
		a{
			@extend .Link--callToAction;
			display:block!important;
			margin-left:auto;
			margin-right:auto;
			width:70%;
			max-width:200px;
		}
	}
	@include respond-to('mobile'){

		width:100%;
		max-width:850px;
		margin-left:auto;
		text-align:left;
		padding-left:10px;
		padding-right:10px;

	}

	strong{
		color:$red;
	}

	ul,ol{

		margin-left:24px;
		padding-left:24px;

		@include respond-to('mobile'){

			margin-left:16px;
			padding-left:16px;

		}
		padding-bottom:1em;

	}
	ul li{

		list-style:disc;

	}
	ol li{
		list-style-type:decimal-leading-zero;

		ol li{

			list-style-type:lower-roman;

		}
	}

	td{
		@include respond-to('mobile'){
			display:block;
			&:first-child{
				border-width:3px;
			}
		}
	}

}

.Content--caption{

	padding-top:0;
	*{

		@extend %u-text--notice;

	}

	width:50%;
	color:$green;

	@include respond-to('mobile'){
		width:70%;
	}

}
.Content.Content--legal{
	padding-top:0;
	a{
		border-color:transparent;
	}

}
.Content.Content--long{

	*{

		@extend %u-text--long;

	}
	
	p,ul,li,ol{
		text-align:left;
	}

	h1,h2,h3,h4,h5,h6{

		text-align:center;


	}

	ol{

		margin-left:24px;
		padding-left:24px;

	}
	ol li{
		list-style-type:decimal-leading-zero;

		ol li{

			list-style-type:lower-roman;

		}
	}

}

.Content.Content--footer{

	
	h1{
		//color:black;
		margin-bottom:0;
	}

}

.Content.Content--instagram{

	
	text-align:center;
	margin:0 auto;
	max-width:none;
	position:relative;

	a{
		border-bottom:none;
		&:hover{
			color:$green;
		}
	}

}

.Content.Content--label{

	padding-top:0;
	padding-left:20px;

}


.Content.Content--small{
	*{

		@extend %u-text--notice;
	}
}

.Image-caption .Content{

	padding-top:10px;
	padding-left:30px;

	@extend %u-text--notice;

	*{
		@extend %u-text--notice;
	}

	@include respond-to('mobile'){
		
		text-align:center;
		padding-left:0;	
	}

}


.Content.Content--notice{

	max-width:none;
	text-align:center;
	padding:10px;
	width:50%;

	@include respond-to('mobile'){
		width:100%;
	}

	margin:0 auto;
	*{

		@extend %u-text--notice;
		color:white;

	}

	p{
		padding:0;

	}

	a{

		border-bottom:1px dotted rgba(white,0.5);
		display:inline-block;
		transition:border 0.4s ease;

		&:hover{

			border-color:transparent;

		}

		&.Link--button{
			margin-top:24px;
			border:1px solid rgba(white,0.5);
			text-decoration:none;
			padding:5px 10px;
			transition:all 0.4s ease;
			&:hover{

				background:white;
				color:$green;

			}

		}

	}


}