/**
 * Settings
 *
 * Core defaults
 */

/**
 * Breakpoints
 *
 * Hold our breakpoint aliases and conditions in a list. These can be invoked
 * later on via the `respond-to()` mixin found in _mixins.scss.
 */

$breakpoints: (
  "mobile"    "(max-width: 745px)",
  "tablet"    "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "desktop"   "(min-width: 1024px)",
  "desktop-plus"      "(min-width: 1200px)",
  "wide"      "(min-width: 1400px)",
  "shallow"    "(min-width: 746px) and (max-height: 1000px)",
  "iphone5"   "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)"
) ;

/**
 * Base Grid & Spacing values
 */

$base-vw: 1024 ;
$base-vh: 768 ;
$base-padding: ('*' : 20px,'mobile' : 10px) ;
$base-grid-gutter: $base-padding ;
$base-max-width: 1400px ;
$base-max-text-width: 700px ;
$base-max-dialog-width: 700px ;

/**
 * Base Type Settings
 */

$base-font-size: 18px ;
$base-line-height: 25px ;
$base-letter-spacing: 0 ;

$base-font-family: 'pitch-semibold', monospace ;
$base-font-family-bold: 'pitch-semibold', monospace ;
$base-font-family-alt: 'pitch-semibold', monospace ;
$base-font-family-hero: 'farsans',monospace;
$base-text-align: left ;

$base-text-families : (

  'sans' : (
    'weights' : (
      'regular' : '"Helvetica", Arial, sans-serif',
      'bold' : '"Helvetica", Arial, sans-serif'
    ),
    'sizes' : (
      'small' : $base-font-size * 0.75,
      'medium' : $base-font-size,
      'large' : $base-font-size * 1.5
    )
  ),
  'mono' : (
    'weights' : (
      'regular' : monospace
    ),
    'sizes' : (
      'medium' : $base-font-size,
      'small' : $base-font-size * 0.75
    )
  )

);

$base-text-applications: (
  'heading' : 'sans--bold--large',
  'body' : 'sans--regular--medium',
  'caption' : 'mono--regular--small'
);

$base-text-weights: ('*' : '20px','mobile' : '10px');
$base-text-sizes: ('*' : '20px','mobile' : '10px');


/**
 * Base Colours
 */

// rgb(140,146,132)

$green: rgb(139,150,130);
$lightGreen : lighten($green,45%);
$red: rgb(110,21,17);

$base-color: #000 ;
$base-background:$green ;
$base-link-color: #000 ;
$base-link-opacity-hover: 1 ;
$base-active-color: #000 ;

$base-overlay-background: #000 ;
$base-overlay-transparency: 0.85 ;

$base-ui-color: rgb(110,21,17) ;
$base-ui-color-alt: #000 ;

$base-placeholder-color: #787878 ;
$base-placeholder-opacity:.3 ;

$base-selection-background: rgba(0,0,0, 1) ;
$base-selection-color: #FFF ;

$base-image-color: #EEE ;
$base-image-color-loaded: #EEE ;
$base-image-fade:2s;
$base-image-delay:0.25s;
$base-image-ease:ease;

/**
 * Base Icons
 */

$base-icons : "facebook" "instagram";
$base-icon-extension: "png";
$base-icon-size : 24px;

$base-icon-glyphs : (
  "deliveroo": "\e900",
  "walk-in" :  "\e90f",
  "eat-in" :  "\e90f",
  "book-now" : "\e912",
  "book-online" : "\e912",
  "reservations" : "\e912",
  "order-swift" : "\e910",
  "take-away" : "\e910",
  "rice" : "\e911",
  "search" : "\e90e",
  "phone" : "\e901",
  "arrow-left": "\e907",
  "arrow-right": "\e908",
  "menu" : "\e909",
  "play" :"\e90a",
  "plus" :"\e90b",
  "cross" : "\e90c",
  "email" : "\e90d",
  "map" :  "\e902",
  "pinterest" : "\e903",
  "twitter" : "\e904",
  "facebook" : "\e905",
  "instagram" : "\e906",
  "delivery": "\e900",
  "slerp": "\e900",
  "order-online": "\e900"
);

/**
 * Base Cursors
 */


$base-cursor-prev:pointer;
$base-cursor-next:pointer;
$base-cursor-play:pointer;
$base-cursor-pause:pointer;

/**
 * Base Sections (Sections being regions that allow you to control core styling)
 * The keys in this array will be used as modifiers (.Section--{key}) with the config settings provided
 * At a config level Sections can define:
 	- background color
 	- foreground color,
 	- heading typeface,
 	- body typeface
 	- padding for the .Section-body
 	- max width for the .Section-body
 	- colour for images
 	- colour links
 	- colour for active items
 */

$base-sections: (

	'*' : (
		'transition' : all 0.4s ease,
		'background' : transparent,
		'color' : $base-color,
		'heading-font-family' : $base-font-family,
		'text-font-family' : $base-font-family,
		'container-padding' : $base-padding,
		'container-max-width' : $base-max-width,
		'image-color' : $base-image-color,
		'link-color' : $base-link-color,
		'active-color' : $base-active-color

	),
	'modifier' : (
		'background' : $base-color,
		'color' : $base-background,
		'heading-font-family' : $base-font-family-alt,
		'text-font-family' : $base-font-family,
		'container-padding' : $base-padding,
		'container-max-width' : $base-max-width,
		'image-color' : $base-background,
		'link-color' : $base-ui-color,
		'active-color' : $base-active-color,
		'when-inside' : (
  		'.Site--modified' : (
    		'background' : $base-color,
    		'color' : $base-background
    	)
    )
	)

);


/**
 * Top level hooks
 */

$base-touch-class: '.Html--touch' ;






