.Container{

	@extend %u-container;
	//max-width:600px;
	margin-left:0;
}

.Container--centered{

	margin-left:auto;

}

.Container--long{

	max-width:1024px;

}